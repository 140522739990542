import type { AxiosError } from 'axios';

// import UnauthorizedError from 'lib/errors/UnauthorizedError';
// import axiosInterceptorError from 'lib/utils/axiosInterceptorError';

import axios from './axios';

/* eslint-disable no-param-reassign */

const client = axios.create();

client.interceptors.response.use(undefined, async (err: AxiosError) => {
  // 인증 오류
  if (err.response?.status === 401) {
    console.log('src/lib/request/clinet.ts err :: ', err);
    // 서버에서 사용자 인증을 처리할 수 없습니다.
    // throw new UnauthorizedError('The server was unable to process user authentication.');
  }

  // axiosInterceptorError(err);

  return Promise.reject(err);
});

export default client;
